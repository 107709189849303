export const Urlconstant = {
	url: "https://ombn.in/dream/",
	BACTH_URL: "https://ombn.in/attendance-batch/",

	// url: "https://ombn.in/Dream-pre/",
	// url: "http://103.174.102.136:8080/dream-dev/",
	// url: "http://localhost:8081/",
	// spreadsheetId: "1NrTU01N_mU-HnPIQjPOzQhf_7CEXH430RzJb6wdDJ-Q"
	spreadsheetId: "1HI3nKYRbXNl2cu9rKwqKp3tznOZ7ziOupokU_daFZ8E"
	// spreadsheetId: "1BBvpbYuTvqaXQ2ooVItm0MGqB5XMnNgpHc0ZUW4zqLg" // pre-Production/
};
